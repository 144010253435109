import React from 'react'
import style from './Utils.module.css'
import background from 'assets/images/background.png'

const ContactUs = () => {
    return (
        <div className={style.Utils} style={{ backgroundImage: `url(${background})` }}>
            <div>
                <h1>Contact Us</h1>
                <ul>
                    <li>Email: <a href="mailto:uriandmackenzie2022@gmail.com" target='_blank' rel='noreferrer'>uriandmackenzie2022@gmail.com</a></li>
                    <li>Phone: <a href="tel://+918010308162" target='_blank' rel='noreferrer'> +91 8010308162</a> (Hours: Monday-Saturday, 11:00 AM to 6:00 PM IST)</li>
                    <li>Facebook: <a href="https://www.facebook.com/people/Uri-Mackenzie/100092207138407/" target='_blank' rel='noreferrer'>https://www.facebook.com/people/Uri-Mackenzie</a></li>
                    <li>Instagram: <a href="https://www.instagram.com/uri_and_mackenzie" target='_blank' rel='noreferrer'>https://www.instagram.com/uri_and_mackenzie</a></li>
                    <li>Youtube: <a href="https://www.youtube.com/@rajivkumar-cs3yz" target='_blank' rel='noreferrer'>https://www.youtube.com/@rajivkumar-cs3yz</a></li>
                </ul>
            </div>

        </div>
    )
}

export default ContactUs