import React from 'react'
import style from './Utils.module.css'
import background from 'assets/images/background.png'

const Refund = () => {
    return (
        <div className={style.Utils} style={{ backgroundImage: `url(${background})` }}>
            <div>
                <h1>Shipment and Return:</h1>
                <p>We offer a 3-day refund policy on all our plants. If you're not satisfied with your purchase, you can request a refund within three days of receiving your order. Please note that the product must be in its original condition and packaging to be eligible for a refund.</p>
                <h2>How to Request a Refund (For more than 3 days)</h2>
                <p>To initiate a refund, please contact us directly via email or phone. Include your order number and the reason for the refund request:</p>
                <ul>
                    <li>Email: <a href="mailto:uriandmackenzie2022@gmail.com" target='_blank' rel='noreferrer'>uriandmackenzie2022@gmail.com</a></li>
                    <li>Phone: <a href="tel://+918010308162" target='_blank' rel='noreferrer'>+918010308162</a> (Hours: Monday-Friday, 9:00 AM to 5:00 PM PST)</li>
                </ul>
            </div>
        </div>
    )
}

export default Refund
