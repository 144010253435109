import React, { useEffect, useState } from 'react'
import style from './Cart.module.css'
import { useSelector } from 'react-redux';
import ProductCart from 'components/common/ProductCart/ProductCart';
import { useNavigate } from 'react-router-dom';
import background from 'assets/images/background.png'
import { roundOff } from 'utils/helper';

const Cart = () => {

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalGST, setTotalGST] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [recipt, setRecipt] = useState([]);

    const navigate = useNavigate();

    const { cart } = useSelector(state => state.products);

    useEffect(() => {
        let total = 0;
        if (cart) {

            let arr = [];

            let totalDiscount = 0
            let totalGST = 0;
            let totalPrice = 0;

            cart.forEach((item) => {
                let obj = {};
                obj.name = item.name;
                obj.detail = item.discount  * item.quantity;
                arr.push(obj);
                
                obj = {};
                obj.name = `${item.name.substring(0, 10)}... GST`;
                obj.detail = roundOff((item.price >= 1000 ? item.price * 0.12 : item.price * 0.05) * item.quantity);
                arr.push(obj);

                totalPrice +=  (item.price >= 1000 ? item.price + (item.price * 0.12) : item.price + (item.price * 0.05)) * item.quantity;
                totalGST += (item.price >= 1000 ? item.price * 0.12 : item.price * 0.05) * item.quantity;
                totalDiscount += (item.discount - item.price) * item.quantity;
            })

            setTotalPrice(roundOff(totalPrice));
            setTotalDiscount(roundOff(totalDiscount));
            setTotalGST(roundOff(totalGST));
            setRecipt(arr);
        }
    }, [cart]);

    if (!cart) {
        return <div className='loaderBg'><div className='loader'></div></div>
    }

    return (
        <div className={style.Cart} style={{ backgroundImage: `url(${background})` }}>
            <h3>My Cart</h3>
            <div>
                <ProductCart products={cart} type={'cart'} />
                {cart?.length !== 0 && <div className={style.detail}>
                    {
                        recipt.map((item) => (<div style={{fontSize: '0.8rem', gap: '1rem'}}>
                            <h3 style={{textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.name}</h3>
                            <h4>₹{item.detail}</h4>
                        </div>))
                    }
                    <div>
                        <h3>Total GST:</h3>
                        <h4>₹{totalGST}</h4>
                    </div>
                    <div>
                        <h3>Total discount:</h3>
                        <h4 style={{ color: '#d50606' }}>- ₹{totalDiscount}</h4>
                    </div>
                    <div>
                        <h3>Delivery Charges:</h3>
                        <h4>₹40</h4>
                    </div>
                    <div>
                        <h3>Delivery discount:</h3>
                        <h4 style={{ color: '#d50606' }}>- ₹40</h4>
                    </div>
                    <div>
                        <h3>Total Price:</h3>
                        <h4>₹{totalPrice}</h4>
                    </div>
                    <button className='border-btn' onClick={() => navigate('/booking')}>Pay ₹{totalPrice}</button>
                </div>}
            </div>
        </div>
    )
}

export default Cart