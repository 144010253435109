import React from 'react'
import style from './Footer.module.css'
import { useNavigate } from 'react-router-dom'
import { MdOutlineCall, MdOutlineLocationOn, MdOutlineMailOutline } from 'react-icons/md'
import { FaFacebookF, FaInstagram, FaPinterest, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from 'assets/images/longLogoInverse.png'

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className={style.Footer}>
            <div>
                <div className={style.top}>
                    <div>
                        <div>
                            <MdOutlineLocationOn />
                            <a target="_blank" rel="noopener noreferrer" href='https://maps.google.com/?q=28.7345027923584,77.0509262084961'>Visit Us</a>
                        </div>
                        <div>
                            <MdOutlineMailOutline />
                            <a target="_blank" rel="noopener noreferrer" href='mailto:uriandmackenzie2022@gmail.com'>@Uri&Mackenzie</a>
                        </div>
                        <div>
                            <MdOutlineCall />
                            <a target="_blank" rel="noopener noreferrer" style={{widht: '100%'}} href='tel://+918010308162'>+91 8010308162
                                Mon - Sat - 11:00 am to 6:00 pm (IST)</a>
                        </div>
                    </div>
                    <div>
                        <h2>About Us</h2>
                        <div>
                            <p onClick={()=>navigate('/aboutUs')} >About Us</p>
                            <p onClick={()=>navigate('/contactUs')} >Contact Us</p>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/people/Uri-Mackenzie/100092207138407/'><FaFacebookF /></a>
                                <a target="_blank" rel="noopener noreferrer" href='https://facebook.com/Uri&Mackenzie'><FaTwitter /></a>
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/uri_and_mackenzie'><FaInstagram /></a>
                                <a target="_blank" rel="noopener noreferrer" href='https://facebook.com/Uri&Mackenzie'><FaPinterest /></a>
                                <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@rajivkumar-cs3yz'><FaYoutube /></a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>Quick Links</h2>
                        <div>
                            <p onClick={() => navigate('/orders')}>My Orders</p>
                            <p onClick={() => navigate('/wishlist')}>My Wishlist</p>
                            <p onClick={() => navigate('/refund')}>Shipping & Return</p>
                            <p onClick={() => navigate('/privacy')}>Privacy Policy</p>
                        </div>
                    </div>
                    <div>
                        <img src={logo} alt='logo' />
                    </div>
                </div>
            </div>
            <div className={style.down}>
                <p>© 2024, URI & Mackenzie. All rights reserved.</p>
                <p>Developed By Bizology.</p>
            </div>
        </div>
    )
}

export default Footer